import React from 'react';

export const DownloadCVButton = ({ isMobile }) => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5rem',
  };

  const downloadLink = '/pdfs/William-Winkler-CV.pdf'; // Adjust the path to your PDF file

  if (isMobile) {
    return (
      <div style={containerStyle}>
        <a href={downloadLink} download className='generate__btn'>
          Download CV
        </a>
      </div>
    );
  }

  return (
    <div className='generate__container' id='resume__generate'>
      <a href={downloadLink} download className='generate__btn'>
        Download CV
      </a>
    </div>
  );
};
