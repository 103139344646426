import React from "react";

export const Languages = ({ languages }) => {
  function getLevelIndicator(level) {
    if (level === 1) {
      return (
        <>
          <i className={`bx bx-radio-circle-marked social__icon`}></i>{" "}
          <i className={`bx bx-radio-circle social__icon`}></i>{" "}
          <i className={`bx bx-radio-circle social__icon`}></i>{" "}
        </>
      );
    } else if (level === 2) {
      return (
        <>
          <i className={`bx bx-radio-circle-marked social__icon`}></i>{" "}
          <i className={`bx bx-radio-circle-marked social__icon`}></i>{" "}
          <i className={`bx bx-radio-circle social__icon`}></i>{" "}
        </>
      );
    } else if (level === 3) {
      return (
        <>
          <i className={`bx bx-radio-circle-marked social__icon`}></i>{" "}
          <i className={`bx bx-radio-circle-marked social__icon`}></i>{" "}
          <i className={`bx bx-radio-circle-marked social__icon`}></i>{" "}
        </>
      );
    }
  }

  return (
    <>
      <section className="technical-skills section" id="skills">
        <h2 className="section-title">Languages</h2>
        <div className="skills__content bd-grid">
          <ul className="skills__data">
            {languages.map((language) => (
              <Language
                key={language.name}
                name={language.name}
                level={getLevelIndicator(language.level)}
              />
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

const Language = ({ name, level }) => (
  <li style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    <div>{name}</div>
    <div>{level}</div>
  </li>
);
