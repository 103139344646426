import React from "react";

export const Skills = ({
  technicalLabel,
  technicalSkills,
}) => {
  return (
    <>
      <section className="technical-skills section" id="skills">
        <h2 className="section-title">{technicalLabel}</h2>
        <div className="skills__content bd-grid">
          <ul className="skills__data">
            {technicalSkills.map((skill, i) => <Skill key={i} skill={skill} />)}
          </ul>
        </div>
      </section>
    </>
  );
};

const Skill = ({ skill }) => (
  <li className="skill">
    <p className="skill__field">{skill.field}</p>
    <p className="skill__elements">{skill.elements}</p>
  </li>
);
