
let protocol = "https://"
let domain = window.location.hostname;
if (domain === "localhost") {
  protocol = "http://"
  domain = "localhost:3000"
}

export const Data = {
  profile: {
    name: "William Winkler",
    ocupation: "Software Engineer",
    location: "Frankfurt am Main, Germany",
    email: "williambwinkler@gmail.com",
    telephone: "+49 17 4257 3964",
    image: "images/portrait.png",
  },
  aboutMe: {
    label: "Profile",
    description:
      "I am a detail-oriented, responsible software engineer with a strong passion for my work especially when it comes to automating and operating stuff. I am committed to delivering excellent results and I am always looking to learn and improve. Above all, I am a team player and value the importance of communication and collaboration.",
  },
  privateInfo: {
    label: "Private",
    description: "I have been living in Frankfurt since 2024, but I'm originally from Denmark. I like to work out and have done athletics since 2012. Throughout my athletic career, I have won several medals in the high jump discipline at Danish championships. In addition to my love for sports, I also enjoy spending a lively evening out with friends, going to meetups & conferences, playing games, building computers or working hobby projects.",
  },
  skills: {
    technicalLabel: "Experienced in",
    technicalSkills: [
      {
        field: "Languages",
        elements: "TypeScript, Python, Go, Rust, C#",
      },
      {
        field: "Frameworks",
        elements: "ReactJS, Flask, .NET, ExpressJS, gRPC, Swagger, NestJS",
      },
      {
        field: "Databases",
        elements: "PostgreSQL, MongoDB, CosmosDB, Redis",
      },
      {
        field: "Clouds",
        elements: "Azure, DigitalOcean, AWS, MongoDB Atlas, Vercel",
      },
      {
        field: "Monitoring",
        elements: "Grafana, Prometheus, Kibana, ElasticSearch",
      },
      {
        field: "Technologies",
        elements: "Docker, Kubernetes, GraphQL, MQTT, RabbitMQ, NodeJS, .NET Core, NGINX, Github Actions, Git, Ansible, HTMX, REST, Tailwind, Handlebars, jest",
      }
    ],
  },
  languages: [
    {
      name: "Danish",
      level: 3, // 1-3
    },
    {
      name: "English",
      level: 3,
    },
    {
      name: "German",
      level: 1,
    }
  ],
  references: [
    {
      "name": "Therese Kristiansen",
      "position": "Product Manager @ Lunar",
      "email": "tesse_k@outlook.com"
    },
    {
      "name": "Anders Kallesøe",
      "position": "Head of IT @ Aarhus Symposium",
      "email": "andka@live.dk"
    }
  ],
  links: {
    label: "LINKS",
    social: [
      {
        label: "william-winkler.com",
        name: "resume",
        url: "https://william-winkler.com",
        className: "bx-user",
      },
      {
        label: "linkedin.com/in/williambwinkler",
        name: "linkedin",
        url: "https://www.linkedin.com/in/williambwinkler/",
        className: "bxl-linkedin-square",
      },
      {
        label: "github.com/williamwinkler",
        name: "github",
        url: "https://github.com/williamwinkler",
        className: "bxl-github",
      },
    ],
  },
  experience: {
    works: [
      {
        title: "Jr. Cloud Engineer, Fulltime",
        period: "Mar. 2024 - Present",
        company: {
          name: "RUBINLAKE",
          url: "https://rubinlake.com",
        },
        description: [
        ],
        links: [
        ]
      },
      {
        title: "Jr. Site Reliability Engineer, Part time",
        period: "Feb. 2023 - Feb. 2024",
        company: {
          name: "Systematic",
          url: "https://systematic.com/en-gb/",
        },
        description: [
          "Maintained, improved and expanded monitoring and alerting for projects.",
          "Developed an internal program to do service discovery and setup monitoring of new services which has been adopted across SRE teams.",
          "Automated the process of creating review tasks for documentation flagged as outdated or incorrect to ensure our documention being as up to date and reliable as possible.",
          "Analyzed and visualized Four Keys metrics like Mean Time To Recovery and Mean Time Between Failures (MTTR & MTBF) helping us identify areas of improvement.",
        ],
        links: [
          {
            label: "See recommendation letter",
            url: protocol + domain + "/pdfs/Systematic-recommendation-letter-William-Bjerregaard-Winkler.pdf",
            logo: "",
          },
        ]
      },
      {
        title: "Full-Stack Developer, Volunteer",
        period: "Dec. 2022 - Jan. 2024",
        company: {
          name: "Aarhus Symposium",
          url: "https://aarhus-symposium.org",
        },
        description: [
          "Developed and maintained a participant management system, designed to handle 1000+ users and PDF submissions for the biggest leadership conference by students in Scandinavia.",
          "Automated the deployment and rollback processes with a CLI tool, which greatly reduced the complexity, chance of human-errors and the time needed resulting in a more frequent and faster deployments and rollbacks.",
          "Reduced the number of bugs and debugging time by introducing logging and monitoring.",
        ],
        links: [

        ]
      },
      {
        title: "Backend Engineer, Intern",
        period: "August - December 2022",
        company: {
          name: "Lunar",
          url: "https://lunar.app",
        },
        description: [
          "Worked with handling applications and loan in Credit & Lending.",
          "Developed and maintained Go-microservices and implemented improvement-of-life features on the internal platform in ReactJS for back office.",
        ],
        links: [
          {
            label: "See recommendation letter",
            url: protocol + domain + "/pdfs/Lunar-recommendation-letter-William-Winkler.pdf",
            logo: "",
          },
        ]
      },
    ],
    academic: [
      {
        career: "Bachelor of Engineering in Software Technology",
        date: "Aug. 2020 - Feb 2024",
        institution: "Aarhus University, Denmark",
        description: "For my bachelor's project, my group and I developed an IoT solution in collaboration with the company Iterator IT. Our project focused on monitoring factors such as temperature, humidity, and CO2 levels in office environments. I am proud to mention that our dedication and hard work led to the project receiving the best grade possible."
      },
    ],
    projects: [
      {
        name: "SITT - (Si)mple (T)ime (T)racking app",
        company: "Hobby project",
        period: "Jun. - Oct. 2024",
        description: [
          "I wanted to build a simple program, to track time on projects ⏱️",
          "I also wanted to experiment with Rust so I combined the two and created SITT. It consists of an API deployed on AWS Lambda using DynamoDB (free tier) and a CLI to easily authenticate & interact with the API."
        ],
        links: [
          {
            label: "github.com/williamwinkler/sitt ⏱️",
            url: "https://github.com/williamwinkler/sitt",
            logo: "",
          },
        ]
      },
      {
        name: "Book Tracker",
        company: "Hobby project",
        period: "Jan. - Feb. 2024",
        description: [
          "A new year resolution of mine was to read more books. Naturally I had to create an application to keep track:",
          "Book Tracker is a simple web app that allows you to keep track of the books you are reading, have read and want to read."
        ],
        links: [
          {
            label: "Book Tracker",
            url: "https://booktracker.william-winkler.com",
            logo: "images/booktracker.ico",
          },
        ]
      },
      {
        name: "Service discovery and monitoring agent",
        company: "Systematic",
        period: "July - Aug. 2023",
        description: [
          "A project was in the process of rewriting their services to another framework, thereby breaking our monitoring and alerting. I successfully developed a solution, a lightweight agent running on the host machinces utilizing the Docker Daemon and exposing scrapejobs for Prometheus, which automatically discovers and monitors the new services, when they were deployed. This eliminated a lot of manual work and toil, and at the same time ensuring quick and consistent monitoring. The agent is now used across SRE teams.",
        ],
        links: [

        ]
      },
      {
        name: "Hearthstone Card Viewer",
        company: "Hobby project",
        period: "Jan - Aug. 2023",
        description: [
          "I wanted to publish my very own webpage with a dedicated backend and decided on creating one for Hearthstone cards since I am a big fan of the game. The webpage is written in ReactJS and the backend in Go. The source codes can be found on my GitHub and a link to the webpage is underneath here.",
        ],
        links: [
          {
            label: "Hearthstone Card Viewer",
            url: "https://hscards.william-winkler.com",
            logo: "images/hscards.ico",
          }
        ]
      },
    //  {
    //     name: "Make services GDPR compliant",
    //     company: "Lunar",
    //     period: "Nov. - Dec. 2022",
    //     description: [
    //       "At Lunar, I successfully transformed the Loan domain's services to being GDPR-compliant. This involved researching all models for personally identifiable data, a thorough understanding of the data flow between services, and bootstrapping methods for legacy data. I developed an event-driven service that kept track of when to anonymize the personally identifiable data of the models. A Kubernetes CronJob was set to daily hit an endpoint in the service that continuously ensured the anonymization throughout the domain, thereby making it GDPR-compliant.",
    //     ],
    //     links: [

    //     ]
    //   },
    ],
  },
};
