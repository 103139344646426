export const Menu = {
  menu: [
    {
      label: "Home",
      section: "#home",
      className: "bx-home",
    },
    {
      label: "Profile",
      section: "#profile",
      className: "bx-user",
    },
    {
      label: "Experience",
      section: "#experience",
      className: "bx-briefcase-alt",
    },
    {
      label: "Education",
      section: "#education",
      className: "bx-book",
    },
    {
      label: "Projects",
      section: "#projects",
      className: "bx-award",
    },
    {
      label: "Links",
      section: "#links",
      className: "bx-link",
    },
  ],
};
