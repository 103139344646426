import React, { useState, useEffect } from 'react';

import { Profile } from '../Components/Profile';
import { Education } from '../Components/Academic';
import { Skills } from '../Components/Skills';
import { Projects } from '../Components/Projects';
import { Works } from '../Components/Works';
import { Links } from '../Components/Links';
import { AboutMe } from '../Components/AboutMe';
import { Private } from '../Components/Private';
import { Menu } from '../Components/Menu';
import { SEO } from '../Components/SEO';
import { Data as dataSchema } from '../Schemas/Data';
import { Menu as menuSchema } from '../Schemas/Menu';
import { References } from '../Components/References';
import { Languages } from '../Components/Languages';
import { DownloadCVButton } from '../Components/DownloadCVButton';

export const Resume = () => {
  const query = '(min-width: 968px)';
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches]);

  const { profile, aboutMe, privateInfo, skills, links, experience, references, languages } = dataSchema;

  return (
    <>
      <SEO {...profile} {...aboutMe} />
      {!matches && <Menu {...menuSchema} />}
      <main className='l-main bd-container' id='bd-container'>
        <div className='resume' id='area-cv'>
          <div className='resume__left'>
            <Profile {...profile} {...links} isMobileView={!matches} />
            <AboutMe {...aboutMe} />
            <Skills {...skills} />
            <Private {...privateInfo} />
            {matches && <Links {...links} />}
            <Languages languages={languages} />
            {matches && <References references={references} />}
          </div>
          <div className='resume__right'>
            <Works {...experience} />
            <Education {...experience} />
            <Projects {...experience} />
            {!matches && <Links {...links} />}
            {!matches && <References references={references} />}
            {!matches && <DownloadCVButton isMobile={true}/>}
          </div>
        </div>
      </main>
    </>
  );
};
