import React from "react";

export const References = ({ references }) => {
  return (
    <>
      <section className="technical-skills section" id="skills">
        <h2 className="section-title">References</h2>
        <div className="skills__content bd-grid">
          <ul className="skills__data">
            {references.map((reference) =>
            <Reference
              key={reference.name}
              name={reference.name}
              position={reference.position}
              email={reference.email}
              />)}
          </ul>
        </div>
      </section>
    </>
  );
};

const Reference = ({ name, position, email }) => (
  <li className="skills__name">
    <span className="skills__circle" />
    <div>
      <p>Name: {name}</p>
      <p>Position: {position}</p>
      <p>Email: {email}</p>
    </div>
  </li>
);
