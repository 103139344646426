import React from "react";

export const Links = ({ label, social: link }) => {
  return (
    <section className="social section" id="links">
      <h2 className="section-title">{label}</h2>
      <div className="social__container bd-grid">
        {link.map((social) => <Link key={social.name} {...social} />)}
      </div>
    </section>
  );
};

export const Link = ({ label, url, logo, className }) => (
  <a href={url} target="_blank" rel="icon" className="social__link">
    <i className={`bx ${className} social__icon`}></i> {label}
    {logo && <img src={logo} alt={label} className="social__logo" height="24px" width="24px" style={{ marginLeft: "5px" }} />}
  </a>
);
